<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="日志">
              <a-input v-model="queryParam['%log']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建人">
              <a-select
                showSearch :filter-option="filterOption"
                allowClear placeholder="创建人"
                style="width: 100%"
                v-model="queryParam['created_user']"
              >
                <a-select-option v-for="op in userOps" :key="op.value" :ops="JSON.stringify(op)">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <!--      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>-->
      <!-- <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button> -->
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <!--          <a @click="handleEdit(record, index)">编辑</a>-->
          <!--          <a-divider type="vertical" />-->
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '创建日志' : '编辑日志'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="日志">
              <a-input v-model="form.log" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="类型">
              <a-input v-model="form.type" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="创建人" prop="full_name">
              <a-input v-model="form.created_user" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark" placeholder="" :rows="3" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getFmsMetaPage, createFmsMetaInfo, updateFmsMetaInfo, deleteFmsMetaInfo } from '@/api/fms'
import { getSysUserMetaOption } from '@/api/system'
import { filterOption } from '@/utils/util'
export default {
  components: {
    STable
  },
  data() {
    return {
      queryParam: {},
      typeOps: {
        1: '审核',
        2: '驳回',
        3: '发票',
        4: '核销',
        5: '反核销',
        6: '凭证确认',
        7: '凭证反确认',
        8: '凭证下载'
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: text => {
            return this.typeOps[text]
          }
        },
        {
          title: '创建人',
          dataIndex: 'created_user',
          extra: {
            type: 'select',
            func: getSysUserMetaOption,
            params: [],
            options: []
          }
        },
        {
          title: '日志',
          dataIndex: 'log'
        }
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   width: '150px',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getFmsMetaPage('fms_log', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        log: '',
        type: '',
        created_user: ''
      },
      rules: {
        created_user: [
          { required: true, message: '请输入创建人', trigger: 'blur' }
        ]
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      userOps: []
    }
  },
  created() {
    getSysUserMetaOption('user', {
    }).then(v => {
      this.userOps = v
    })
  },
  methods: {
    filterOption,
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        code: '',
        name: '',
        full_name: '',
        remark: ''
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteFmsMetaInfo('fms_log', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createFmsMetaInfo
          if (this.modalFlag === 'update') {
            func = updateFmsMetaInfo
          }
          func('fms_log', this.form).then(_ => {
            this.confirmLoading = false
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
            })
            if (this.modalFlag === 'create') {
              this.$refs.table.refresh(true)
            }
          }).catch(_ => {
            this.formVisible = false
            this.confirmLoading = false
          })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
